export default function ContactMap() {
    return (
      <div className="contact_map">
        <div className="map_container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60833.500488113394!2d75.87240139947922!3d17.645716815823384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc5d98bb1b6e57b%3A0xa6a8e2a603ae8ad4!2sESPEE%20INDUSTRIES!5e0!3m2!1sen!2sin!4v1701912575239!5m2!1sen!2sin"
            width="600"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    );
  }
  