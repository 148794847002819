import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import 'firebase/storage';

// const firebaseConfig = {
//   apiKey: "AIzaSyDaWtG30yOHZ7XSeLpxyKnGJeSxfJvq50A",
//   authDomain: "cellenza-c8ce3.firebaseapp.com",
//   projectId: "cellenza-c8ce3",
//   storageBucket: "cellenza-c8ce3.appspot.com",
//   messagingSenderId: "679349310285",
//   appId: "1:679349310285:web:336098c424ed64e741dd04",
//   measurementId: "G-0EVJ6LZ9PK",
// };

const firebaseConfig = {
  apiKey: "AIzaSyC0QfyQdiK1J7SnuA7Od_z9SSFbHuxLp_0",
  authDomain: "cellenza-85816.firebaseapp.com",
  projectId: "cellenza-85816",
  storageBucket: "cellenza-85816.appspot.com",
  messagingSenderId: "722413385981",
  appId: "1:722413385981:web:28b8937dc7854834b42694"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export { auth, db, storage };
